import { withAuthenticationRequired } from '@auth0/auth0-react';

import AppFrame from '@/components/AppFrame';
import DomainsCreate from '@/components/DomainsCreate';

export function DomainsCreateIndex({
  organizationId,
}: {
  organizationId: string;
}) {
  return (
    <AppFrame organizationId={organizationId}>
      <DomainsCreate organizationId={organizationId} />
    </AppFrame>
  );
}

export default withAuthenticationRequired(DomainsCreateIndex);
