import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const defaultProps = {};

type DomainsCreateViewProps = {
  organizationId: string | number;
} & typeof defaultProps;

export function DomainsCreateView({ organizationId }: DomainsCreateViewProps) {
  const { t } = useTranslation();
  return (
    <Box mb={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          component={GatsbyLink}
          to={`/organizations/${organizationId}`}
          underline="hover"
        >
          {organizationId}
        </Link>
        <Link
          color="inherit"
          component={GatsbyLink}
          to={`/organizations/${organizationId}/domains`}
          underline="hover"
        >
          {t(`domains.domains`)}
        </Link>
        <Typography color="text.primary">{t(`domains.add-domain`)}</Typography>
      </Breadcrumbs>
      <Stack
        direction={{ xs: `row`, sm: `row` }}
        justifyContent="space-between"
      >
        <Typography variant="h1" color="inherit" sx={{ mb: { xs: 1, sm: 0 } }}>
          {t(`domains.add-domain`)}
        </Typography>
      </Stack>
    </Box>
  );
}

DomainsCreateView.defaultProps = defaultProps;

export default DomainsCreateView;
