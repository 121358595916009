import DomainsCreateView from '@/components/DomainsCreate/DomainsCreateView';

const defaultProps = {};

type DomainsCreateContainerProps = {
  organizationId: string | number;
} & typeof defaultProps;

export function DomainsCreateContainer({
  organizationId,
}: DomainsCreateContainerProps) {
  return <DomainsCreateView organizationId={organizationId} />;
}

DomainsCreateContainer.defaultProps = defaultProps;

export default DomainsCreateContainer;
